.modal {
    transition: all 350ms ease;
    width: 500px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 20px;
    z-index: 100000;
    .modal--title {
        display: flex;
        align-items: center;
        max-height: 40px;
        justify-content: space-between;
        span {
            font-weight: 600; }
        &.end {
            justify-content: flex-end; } }
    .modal--content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column; }
    .modal--success {
        margin: 0 auto;
        .success--rounded {
            width: 160px;
            height: 160px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: map-get($colors, success);
            border-radius: 80px;
            margin: 20px auto; }
        p {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            margin: 20px 0;
            display: flex;
            justify-content: center; } }

    .button {
        align-self: flex-start; }


    &.integration {
        width: 850px; }

    .integration {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;
        .input-field {
            width: auto; } } }
