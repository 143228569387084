.header {
    width: 100vw;
    background-color: map-get($colors, accent);
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 0 0 25px 25px;

    .container {
        justify-content: space-between;
        padding: 10px;
        align-items: center; }

    .header--kate {
        max-width: 133px; }
    .header--user {
        display: flex;
        align-items: center;
        span {
            padding: 0 15px;
            font-size: 32px; } }

    &.big {
        .container {
            justify-content: center; }
        .header--kate {
            max-width: 300px; } } }
