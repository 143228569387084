* {
    margin: 0;
    padding: 0;
    outline: 0;
    border: none;
    box-sizing: border-box; }
html {
    background-color: map-get($colors, background);
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: map-get($colors, text);
    font-family: 'Inter', sans-serif; }
h1 {
    font-size: 32px;
    display: block; }

img {
    max-height: 100%;
    max-width: 100%; }

a {
    color: unset;
    text-decoration: none; }

