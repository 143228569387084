.product-page {
    h1 {
        font-size: 26px; }
    .title {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px; }
    .banner {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        .banner--img {
            background-color: #fff;
            width: 260px;
            height: 260px;
            padding: 10px;
            margin: 0 20px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            img {
                width: 100%;
                height: 100%; } }
        p {
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;
            padding-bottom: 15px; } }
    section {
        margin: 40px 0;
        .asks {
            margin: 15px 0;
            display: flex;
            align-items: flex-start;
            .kate {
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                min-height: 200px;
                margin: 5px;
                margin-left: 20px;
                padding: 5px;

                p {
                    font-size: 24px;
                    line-height: 29px;
                    margin: 10px 0 20px; }

                .button {
                    margin: 0;
                    margin-right: 15px; } }
            &.answered .card {
                background-color: map-get($colors, secondary);
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 40px;
                    height: 35px;
                    background-color: map-get($colors, secondary);
                    bottom: 100%;
                    left: 50px;
                    clip-path: polygon(50% 50%, 0% 100%, 100% 100%); } } } } }
