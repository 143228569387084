@import 'u-variables';
@import 'u-reset';
@import './components/c-container';
@import './components/c-header';
@import './components/c-productPage';
@import './components/c-productsPage';
@import './components/c-configPage';
@import './components/c-inputs';
@import './components/c-product';
@import './components/c-button';
@import './components/c-search';
@import './components/c-modal';
@import './components/c-card';

.page {
    margin-top: 30px;
    max-width: 100%;
    width: 100%;
    .title {
        display: flex; } }

.flex {
    width: 100%;
    display: flex;
    &.between {
        justify-content: space-between; }
    &.ycenter {
        align-items: center; }
    &.xcenter {
        justify-content: center; }
    &.column {
        flex-direction: column; } }

span.link {
    border-bottom: 2px solid black;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer; }
.capitalize {
    text-transform: capitalize; }
.wpp {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 22px;
    padding: 10px 20px;
    margin: 20px 0;
    color: white;
    background: #25D366;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 350px;
    span {
        margin: 0; }
    svg {
        height: 24px;
        widht: 24px;
        fill: white;
        margin-left: 15px; } }
