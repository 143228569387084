.product {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    border-radius: 20px;
    background-color: rgb(200, 200, 200);
    padding: 15px;
    .product--image {
        height: 180px;
        width: 180px;
        margin: 5px auto; }
    .product--title {
        width: 100%;
        font-size: 22px;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
    .product--price {
        line-height: 22px;
        width: 100%; }
    .product--button {
        width: 100%; } }
