.products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    // grid-auto-columns: 280px
    gap: 40px 50px;
    margin: 10px auto; }
.button.rounded {
    margin: 2px 20px; }
.noProducts {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    p {
        margin: 10px 0 20px 0; } }
.platform {
    text-transform: capitalize; }
