.card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    min-height: 200px;
    width: 795px !important;
    min-width: 795px !important;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
        font-size: 24px; }
    .info {
        font-size: 18px;
        display: block; }
    &.red h1 {
        color: map-get($colors, danger); } }
