.input-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin-bottom: 10px;
    flex-direction: column;
    span {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px; }
    input, select {
        border-radius: 25px;
        width: 330px;
        font-size: 18px;
        padding: 10px 15px;
        background-color: #f1f1f1;
        &.auth {
            width: 100%; } } }
