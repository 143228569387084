.search {
    width: auto;
    background-color: white;
    flex-direction: row;
    border-radius: 25px;
    height: 50px;
    align-items: center;
    padding: 0 10px;
    input {
        background-color: transparent; } }
