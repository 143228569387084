.config-page {
    .title {
        justify-content: space-between;
        align-items: center; }
    .config--section {
        margin: 40px 0; }
    .input-field span {
        line-height: 29px;
        font-size: 24px; }
    p {
        display: flex;
        align-items: center; }
    .sync {
        text-transform: capitalize; } }
